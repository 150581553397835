<template>
    <div class="table__cell table__cell--name">
        <div class="cell__inner">
            <div class="cell__inner-avatar">
                <img :src="student.image_zoom_out || defaultAvatar" alt=""/>
            </div>

            <div class="cell__inner-text">
                <span class="cell__title">
                    {{ student.full_name }}
                </span>
                <span class="cell__position">{{ student.position }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';
export default {
    name: "Student",
    data() {
        return {
            defaultAvatar
        }
    },
    props: {
        student: {
            type: Object,
            required: true
        },
    }
}
</script>

<style scoped>

</style>
