<template>
    <div class="table__row table__row--body">
        <student :student="result.user"></student>
        <status :info="result.info"></status>
        <module v-for="module in result.modules" :key="`module_${module.id}`" :module="module"></module>
    </div>
</template>

<script>
import Student from "@/components/course/results/Student";
import Status from "@/components/course/results/Status";
import Module from "@/components/course/results/Module";
export default {
    name: "Result",
    components: {
        Module,
        Status,
        Student
    },
    props: {
        result: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
